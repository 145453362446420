// Copyright 2020 Dgraph Labs, Inc. and Contributors
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

export const Unknown = Symbol("Unknown");
export const Fetching = Symbol("Fetching");
export const FetchError = Symbol("FetchError");
export const OK = Symbol("OK");

export const LoggedIn = Symbol("LoggedIn");
export const Anonymous = Symbol("Anonymous");

export const QUERY_TIMEOUT_DEFAULT = 20; // Seconds
export const SERVER_HISTORY_LENGTH = 5;
